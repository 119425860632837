import { MutationFunction, QueryFunction } from '@tanstack/vue-query';
import { useRoute } from 'vue-router';

import { createMaintenanceError } from '@/functions/maintenance/views';
import { MaintenancePattern } from '@/functions/maintenance';
import { filterMaintenance } from '@/functions/maintenance/views/utils';

/*
  使用例
  export const useSaveSessionCustomerInfo = () => {
    const { mutationFnWrapper } = useMaintenanceWrapper();
    return useMutation({
      mutationFn: mutationFnWrapper(
        async (param: {
          cmsData: GetCustomerInfoInputResponse;
          formValues: CustomerInfoInputFormValues;
        }): Promise<SaveSessionCustomerInfoResponse> => {
          const client = new HttpClient();
          return await client.post(API_URL.SAVE_SESSION_CUSTOMER_INFO, toRequest(param));
        }
      ),
    });
  };
 */

/**
 *  現在の画面に対する閉塞制御チェック
 */
export const useMaintenanceWrapper = () => {
  const pageMeta = useRoute().meta;

  const checkMaintenance = async () => {
    const { patterns } = pageMeta;
    const matched = await filterMaintenance({
      patterns: (patterns || []) as MaintenancePattern[],
    });

    if (matched.length > 0) {
      const { layout, description, functionName } = matched[0];
      throw createMaintenanceError({
        layout,
        description,
        functionName,
      });
    }
  };

  const mutationFnWrapper = <T, U>(fn: (variables: U) => Promise<T>): MutationFunction<T, U> => {
    return async (variables: U): Promise<T> => {
      await checkMaintenance();
      return await fn(variables);
    };
  };

  const queryFnWrapper = <T>(fn: () => Promise<T>): QueryFunction<T> => {
    return async (): Promise<T> => {
      await checkMaintenance();
      return await fn();
    };
  };

  return {
    mutationFnWrapper,
    queryFnWrapper,
  };
};
